/* co trza:
aby czcionki reagowały na device
font families - jak najlepiej wprowadzić google fonts
dodać bold, etc.
czy gdzieś dajemy global css jak mają zachowywać sie boldy?

MUI DISABLE ściąganie roboto

jak rozwiązać wszystkie <li>
*/

// require("typeface-open-sans")
// require("typeface-cabin")


// https://www.npmjs.com/package/@fontsource/cabin
// import "@fontsource/cabin"
import "@fontsource/cabin/400.css";
import "@fontsource/cabin/400-italic.css";
import "@fontsource/cabin/700.css";
import "@fontsource/cabin/700-italic.css";



// import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; 
import "@fontsource/source-sans-pro/400-italic.css"; 
import "@fontsource/source-sans-pro/700.css"; 
import "@fontsource/source-sans-pro/700-italic.css"; 


// Weights: [400,500,600,700]
// Styles: [italic,normal]



// https://www.npmjs.com/package/@fontsource/open-sans
import "@fontsource/open-sans"
// Weights: [300,400,500,600,700,800]
// Styles: [italic,normal]



// const bodyFontFamily = "Source Sans Pro";
const bodyFontFamily = "Cabin";

const getTypography = ({ palette = {} }) => {


  // console.log(palette)
  return ({

    h1: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '2.7rem',
      fontWeight: 700,
      lineHeight: 1,
      // color: palette?.text?.primary

    },
    h2: {
      fontFamily: [bodyFontFamily, 'sans-serif'].join(','),
      fontSize: '2.4rem',
      marginBottom: "8px",

      lineHeight: 1,
      // fontWeight: 700,
      // color: palette?.text?.primary
    },
    h3: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      fontSize: '1.4',
      fontWeight: 700,
      // color: palette?.text?.primary

    },
    h4: {
      fontFamily: ['Open Sans', 'sans-serif'].join(','),
      // fontSize: '1.3rem',
      fontWeight: 700,
      // color: palette?.text?.primary

    },
    h5: {
      fontFamily: [bodyFontFamily, 'sans-serif'].join(','),
      // fontWeight: 700,
      // marginBottom: "4px",

      fontSize: '1.2rem',
      // color: palette?.text?.primary

    },
    a: {
      // color: "white important!"
      // fontSize: "0.7rem",
    },
    tableSubitem: {
      fontFamily: [bodyFontFamily, 'sans-serif'].join(','),
      fontWeight: 700,
      fontSize: '0.7rem',
    },

    body1: {
      fontFamily: [bodyFontFamily, 'sans-serif'].join(','),
      fontSize: '0.8rem',

      // color: palette?.text?.primary

    },
    body2: {
      fontFamily: [bodyFontFamily, 'sans-serif'].join(','),
      // color: palette?.text?.primary

    },
    subtitle: {
      fontFamily: [bodyFontFamily, 'sans-serif'].join(','),
    },
    caption: {
      fontFamily: [bodyFontFamily, 'sans-serif'].join(','),
      fontWeight: "auto",
      // color: palette?.text?.primary,


    },
    button: {
      // fontFamily: ['Cabin','sans-serif'].join(','),
      // fontSize: '0.8rem',
      // fontWeight: 900,

    }

  })
}


export default getTypography;