import { createTheme } from "@mui/material";
import { grey, red, green, blue, orange, deepOrange, purple, pink } from "@mui/material/colors";
import getTypography from "./typography";



const isLight = true





const theme = createTheme({
    palette: {
        //   mode: 'dark',
        primary: {
            light: pink[200],
            main: true ? "#7B638A" : pink[500],
            dark: "#82154D", // ciemny orange
            // contrast: "#ffffff"
        },
        green: {
            // main: "#284235",
            main: "#7B638A", // fiolet
            
        },
        
      paper: {
        light: "#cf3585",
        main: isLight ? "white" : "#2e3f51",
        dark: isLight ?  "#EFE8E1" : "#1b252f",
        contrastText: "black"

      },
    },
    typography: {
        fontSize: 14,
        ...getTypography({}),
      },
    niceShadows: {
        stripe: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
        try: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
        delicate: "rgba(149, 157, 165, 0.2) 0px 0px 12px",
        pink: "0px 6px 10px 6px #d73c7b42",
        cell: "rgb(50 50 93 / 0%) 2px 1px 60px 0px, rgba(0, 0, 0, 0.3) 2px 4px 36px -18px"
    }

});

export default theme;